import React from "react";
import { Card, List, Avatar, Typography } from "antd";
import { ReactComponent as Swimming } from "../icons/sensors/swimming.svg";

interface Props {
  name: string;
  value?: string;
  image?: string;
}

const BathTempCard: React.FC<Props> = ({ name, value, image }) => {
  return (
    <Card
      className="bathtemp-card"
      style={{ backgroundImage: `url(${image || ""})` }}
    >
      <div className="info">
        <List.Item>
          <Avatar
            style={{ backgroundColor: "white" }}
            size="large"
            icon={<Swimming />}
          />
          <div className="list-item-container">
            <Typography.Paragraph type="secondary">{name}</Typography.Paragraph>
            <Typography.Paragraph strong>{value}</Typography.Paragraph>
          </div>
        </List.Item>
      </div>
    </Card>
  );
};

export default BathTempCard;
