import React, { useEffect, useState, useMemo } from "react";
import { List, Typography, Avatar } from "antd";
import { getFormattedSensor } from "../helpers/devices";
import { QuestionCircleOutlined } from "@ant-design/icons";
import config from "../config";

interface Props {
  devices_state: DevicesState;
}

interface ListItem {
  title: string;
  description: string;
  icon: JSX.Element;
}

const SensorList: React.FC<Props> = ({ devices_state }) => {
  const [list_items, setListItem] = useState<ListItem[]>([]);
  const loading = useMemo(() => {
    return list_items.length <= 0;
  }, [list_items]);

  useEffect(() => {
    if (!devices_state.loading) {
      let part_list: ListItem[] = [];

      config.sensorlist.forEach((config_device) => {
        const state_device = devices_state.devices.find(
          (d) => d.id === config_device.id
        );

        if (state_device) {
          const items = config_device.sensors.reduce((acc, type) => {
            if (state_device.data[type]) {
              const formatted = getFormattedSensor(
                type,
                state_device.data[type].value
              );
              acc.push({
                title: formatted.label,
                description: formatted.value,
                icon: formatted.getIcon ? (
                  formatted.getIcon()
                ) : (
                  <QuestionCircleOutlined />
                ),
              });
            }
            return acc;
          }, [] as ListItem[]);

          part_list = [...part_list, ...items];
        }
      });

      setListItem(part_list);
    }
  }, [devices_state.devices]);

  return (
    <div className="sensor-list">
      {devices_state.devices.length > 0 && (
        <List
          loading={loading}
          dataSource={list_items}
          renderItem={(item) => (
            <List.Item style={{ backgroundColor: "white" }}>
              <Avatar
                style={{ backgroundColor: "#fefefe", padding: 4 }}
                size="large"
                icon={item.icon}
              />
              <div className="list-item-container">
                <Typography.Paragraph type="secondary">
                  {item.title}
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  {item.description}
                </Typography.Paragraph>
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default SensorList;
