import React, { FC } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainView from "./Views/MainView";

const app: FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <MainView />
        </Route>
      </Switch>
    </Router>
  );
};

export default app;
