import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { getFormattedSensor } from "../helpers/devices";
import config from "../config";
import BathTempCard from "./BathTempCard";

interface Props {
  devices_state: DevicesState;
}

interface BathListItem {
  name: string;
  device: string;
  value?: string;
  image?: string;
}

const BathTempList: React.FC<Props> = ({ devices_state }) => {
  const [list_items, setListItems] = useState<BathListItem[]>(
    config.bathtemp.places.map((p) => ({
      name: p.name,
      device: p.device,
      image: p.image,
    }))
  );

  useEffect(() => {
    if (!devices_state.loading) {
      const to_set = list_items.map((item) => {
        const device = devices_state.devices.find((d) => d.id === item.device);
        if (device && device.data["temperature"]) {
          return {
            ...item,
            value: getFormattedSensor(
              "temperature",
              device.data["temperature"].value
            ).value,
          };
        }

        return item;
      });

      setListItems(to_set);
    }
  }, [devices_state.devices]);

  return (
    <div className="bathtemp-list">
      <Typography.Title level={3} style={{ paddingLeft: 16 }}>
        Rosendal
      </Typography.Title>
      <div className="outer-list-container">
        <div className="list-container">
          {list_items.map((item, i) => (
            <BathTempCard
              key={i}
              name={item.name}
              value={item.value}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BathTempList;
