import React, { useMemo } from "react";
import { getWeatherInfo } from "../helpers/weather";
import { Typography, Spin, Card } from "antd";

interface Props {
  weather: WeatherState;
}

const WeatherView: React.FC<Props> = ({ weather }) => {
  const weather_info = useMemo(() => {
    return getWeatherInfo(
      (weather.data["weather_state"]?.value as number) || -1
    );
  }, [weather]);

  return (
    <div className="weather-info">
      <Card className="card" style={{backgroundColor: weather_info.color }}>
        <div className="weather-info-inner">
          {weather.loading ? (
            <Spin />
          ) : (
            <>
              {weather_info.icon && <img alt="" src={weather_info.icon} />}
              <Typography.Title level={3}>
                Uppsala{" "}
                {weather.data["temperature"] ? (
                  <span className="temperature">
                    {weather.data["temperature"].value}&deg;
                  </span>
                ) : (
                  <span className="temperature"> - </span>
                )}
              </Typography.Title>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default WeatherView;
