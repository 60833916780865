import Studentvikens from "./images/studentvikens.jpg";

export default {
  devices: ["70B3D57BA0000729"],
  api: {
    senseview: {
      api_uri: "https://api.uppsala.allbin.se/public/devices",
      ws_uri: "wss://api.uppsala.allbin.se/ws",
    },
    smhi: {
      api_uri: "https://opendata-download-metanalys.smhi.se/api",
    },
  },
  weather: {
    lat: "59.858131",
    lon: "17.644621",
  },
  bathtemp: {
    places: [
      {
        name: "Studentviken Badplats",
        device: "70B3D580A01060B6",
        image: Studentvikens,
      },
    ],
  },
  sensorlist: [
    {
      id: "70B3D57BA0000729",
      sensors: [
        "air-humidity",
        "air-temperature",
        "barometric-pressure",
        "co2-concentration",
      ],
    },
  ],
};
