import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import CloudIcon from "../icons/weather/cloudy.svg";
import SunIcon from "../icons/weather/sun.svg";
import RainIcon from "../icons/weather/light-rain.svg";
import config from '../config';

const getWeatherInfo = (
  value: number
): { color: string; icon: string | undefined } => {
  switch (value) {
    case 1:
    case 2:
    case 3:
    case 4:
      return {
        color: "#FDF4C4",
        icon: SunIcon,
      };
    case 5:
    case 6:
    case 7:
      return {
        color: "#DDDDE3",
        icon: CloudIcon,
      };
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
      return {
        color: "gray",
        icon: RainIcon,
      };
    default:
      return {
        color: "",
        icon: undefined,
      };
  }
};

const getWeather = (): Promise<WeatherData> => {
  const req_config: AxiosRequestConfig = {
    baseURL: config.api.smhi.api_uri,
    url: `/category/mesan1g/version/2/geotype/point/lon/${config.weather.lon}/lat/${config.weather.lat}/data.json`,
    method: "GET",
  };

  return new Promise<WeatherData>((resolve, reject) => {
    axios
      .request<any, AxiosResponse<MESANPointAnalysis>>(req_config)
      .then((res) => {
        const formatted: WeatherData = {};

        const air_temp_data = res.data.timeSeries[0].parameters.find(
          (p) => p.name === "t"
        );

        if (air_temp_data) {
          formatted["temperature"] = {
            value: air_temp_data.values[0],
            unit: "degree_celsius",
            time: res.data.approvedTime,
          };
        }

        const weather_state_data = res.data.timeSeries[0].parameters.find(
          (p) => p.name === "Wsymb2"
        );

        if (weather_state_data) {
          formatted["weather_state"] = {
            value: weather_state_data.values[0],
            time: res.data.approvedTime,
          };
        }

        resolve(formatted);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
};

export { getWeather, getWeatherInfo };
