import React from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config";
import { CO2, Humidity, Pressure, Temperature } from "../icons";

const isWSSensorMessage = (o: any): o is WSData => {
  return "type" in o && "message" in o;
};

const getDevices = async (device_ids: string[]): Promise<Device[]> => {
  return Promise.all(
    device_ids.map(async (id) => {
      const req_config: AxiosRequestConfig = {
        baseURL: config.api.senseview.api_uri,
        url: `/${id}`,
        method: "GET",
      };

      try {
        const res = await axios.request<any, AxiosResponse<ServerDevice>>(
          req_config
        );
        return formatDevice(res.data);
      } catch (e) {
        console.error(`Device with id ${id} is unavailable`);
      }
    })
  ).then((arr) => {
    return arr.filter((val) => val !== undefined) as Device[];
  });
};

const formatDevice = (server_device: ServerDevice): Device => {
  const formatted: Device = {
    id: server_device.device.id,
    data: {},
  };

  server_device.data.forEach((data) => {
    formatted.data[data.sensor_id] = { value: data.value[0], time: data.time };
  });

  return formatted;
};

const getFormattedSensor = (
  type: string,
  value: number | string
): FormattedSensor => {
  switch (type) {
    case "air-humidity":
      return {
        value: `${(value as number).toFixed(1)}%`,
        label: "Luftfuktighet",
        getIcon: () => <Humidity />,
      };
    case "barometric-pressure":
      return {
        value: `${((value as number) / 1000).toFixed(1)} kPa`,
        label: "Lufttryck",
        getIcon: () => <Pressure />,
      };
    case "air-temperature":
      return {
        value: `${(value as number).toFixed(1)}\xB0`,
        label: "Temperatur",
        getIcon: () => <Temperature />,
      };
    case "co2-concentration":
      return {
        value: `${(value as number).toFixed(1)} ppm`,
        label: "CO2 Koncentration",
        getIcon: () => <CO2 />,
      };
    default:
      return { value: "", label: "" };
  }
};

export { getDevices, isWSSensorMessage, getFormattedSensor };
