import React, { useState, useEffect } from "react";
import WeatherView from "../Components/WeatherView";
import SensorList from "../Components/SensorList";
import { getWeather } from "../helpers/weather";
import { getDevices, isWSSensorMessage } from "../helpers/devices";
import safeJsonParse from "../helpers/jsonHelper";
import config from "../config";
import BathTempList from "../Components/BathTempList";
import { Typography } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as Logo } from "../icons/senseview_black.svg";

const client = new WebSocket(config.api.senseview.ws_uri);

const MainView: React.FC = () => {
  const [weather, setWeather] = useState<WeatherState>({
    loading: true,
    data: {},
  });
  const [devicesState, setDevicesState] = useState<DevicesState>({
    loading: true,
    devices: [],
  });

  useEffect(() => {
    getWeather()
      .then((data) => {
        setWeather({ loading: false, data: data });
      })
      .catch((e) => {
        console.error(e);
      });

    getDevices(config.devices)
      .then((data) => {
        setDevicesState({ loading: false, devices: data });
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (devicesState.devices.length > 0) {
      client.onopen = (e) => {
        console.log("WS Connected");
      };

      client.onmessage = (m) => {
        const result = safeJsonParse(isWSSensorMessage)(m.data).parsed;

        if (result && result.type === "DATAPOINT_ADD") {
          const devices = [...devicesState.devices];
          const index = devices.findIndex(
            (d) => d.id === result.message.device_id
          );

          if (index > -1) {
            const to_update = { ...devices[index] };
            devices[index] = to_update;
            to_update.data[result.message.sensor_id] = {
              time: result.message.time,
              value: result.message.value[0],
            };

            setDevicesState({ ...devicesState, devices });
          }
        }
      };
    }
  }, [devicesState.devices.length]);

  return (
    <div className="mainview">
      <WeatherView weather={weather} />
      <BathTempList devices_state={devicesState} />
      <SensorList devices_state={devicesState} />
      <div className="footer">
        <Typography.Text strong>
          Powered by{" "}
          <Typography.Link href="https://senseview.se" target="_blank">
            SenseView
          </Typography.Link>
        </Typography.Text>
        <Icon style={{ fontSize: 40, marginLeft: 8 }} component={Logo} />
      </div>
    </div>
  );
};

export default MainView;
